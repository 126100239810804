import { FC, useEffect } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import FileSaver from 'file-saver';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';

interface peLVaRChartProps {
  data: any;
  firstLineKey: number;
  secondLineKey: number;
  formatOption: 'percentage' | 'decimal';
  firstValueName: string;
  secondValueName: string;
}

interface peLVaRBarChartData {
  date: string;
  bar1: number;
  bar2: number;
  QuantileRisk: number;
  LiquidityQuantileRisk: number;
}

function buildPeLVaRBarChartData(
  data: any,
  firstLineKey: number,
  secondLineKey: number
): peLVaRBarChartData[] {
  if (!data.data.length) return [];
  const timeSeriesData = data.data[0];
  const chartData: peLVaRBarChartData[] = [];

  timeSeriesData.forEach((value: any) => {
    chartData.push({
      date: value[0],
      bar1: value[firstLineKey],
      bar2: value[secondLineKey] - value[firstLineKey],
      QuantileRisk: value[firstLineKey],
      LiquidityQuantileRisk: value[secondLineKey],
    });
  });

  return chartData;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    gap: '0.2rem',
    margin: '0 3rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '30rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipBar1: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue_lighter,
  },
  tooltipBar2: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>{label}</div>
          <hr />
          <div className={classes.tooltipBar1}>
            Quantile Risk:{' '}
            {percentageToNdecialPlaces(payload[0].value / 100, 2)}
          </div>
          <div className={classes.tooltipBar2}>
            Liquidity Quantile Risk:{' '}
            {percentageToNdecialPlaces(
              (payload[0].value + payload[1].value) / 100,
              2
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const PeLiquidityValueAtRiskBarChart: FC<peLVaRChartProps> = ({
  data,
  firstLineKey,
  secondLineKey,
  formatOption,
  firstValueName,
  secondValueName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const barChartData = buildPeLVaRBarChartData(
    data,
    firstLineKey,
    secondLineKey
  );

  const title = `Liquidity Quantile Risk vs. Quantile Risk`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'liquidityValueAtRiskBarChart',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title: title,
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: 'center',
        dontIncrementYPosition: undefined,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonGroupContainer}>
          <ExportButton
            fields={['date', 'QuantileRisk', 'LiquidityQuantileRisk']}
            fieldsMap={[
              {
                label: 'Date',
                key: 'date',
              },
              {
                label: 'QuantileRisk',
                key: 'QuantileRisk',
              },
              {
                label: 'LiquidityQuantileRisk',
                key: 'LiquidityQuantileRisk',
              },
            ]}
            exportData={barChartData}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={500}
          height={400}
          data={barChartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 20,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis
            dataKey="date"
            interval={0}
            angle={-45}
            textAnchor="end"
            height={80}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '150%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Quantile Risk (%)'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey="bar1" stackId="a" fill={mainColors.mainBlue_lighter} />
          <Bar dataKey="bar2" stackId="a" fill={mainColors.mainBlue} />
          <Legend
            verticalAlign="bottom"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
            formatter={(item) => {
              switch (item) {
                case 'bar1':
                  return 'Quantile Risk';
                case 'bar2':
                  return 'Liquidity Quantile Risk';
                default:
                  return item;
              }
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default PeLiquidityValueAtRiskBarChart;
