import React from 'react';
import SelectAFundPage from './selectAFundPage/component';
import { GeneralOverviewPageRenderedComponentProps } from '../../../types/components/componentTypes';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { Section } from '../../../types/server-data/ClientConfig';

const GeneralSelectAFundOverview: React.FC<
  GeneralOverviewPageRenderedComponentProps
> = (props) => {
  const sectionDetailsSelector = createSectionByIdSelector(props.section);
  const sectionDetails: Section | null = useSelector(sectionDetailsSelector);

  return (
    <SelectAFundPage
      section={props.section}
      fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
    />
  );
};

export default GeneralSelectAFundOverview;
