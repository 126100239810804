import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../styling/theme';
import GeneralCard from '../../../general/GeneralCard';

const useStyles = makeStyles({
  container: {
    maxHeight: '36em',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
    padding: '0.5em 1em 2em',
  },
  header: {
    color: mainColors.pumpkin,
    fontSize: '2.5em',
  },
  list: {
    padding: '0',
    margin: '0',
  },
  item: {
    margin: '1em 0',
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  time: {
    color: greys.grey400,
    width: '5em',
    fontSize: '1.5em',
    flexShrink: 0,
  },
  text: {
    fontSize: '1.5em',
    fontWeight: 'medium',
    flexGrow: 1,
    color: 'black',
    textDecoration: 'none',
    transition: 'color 0.2s',
    '&:hover': {
      color: mainColors.mainBlue,
    },
  },
});

interface NewsData {
  title: string;
  time: number;
  link: string;
}

export interface NewsProps {
  which_news: string;
}

const NewsComponent: React.FC<NewsProps> = (
  ///////// For later
  which_news
  //////////////////
) => {

  const [newsData, setNewsData] = useState<NewsData[]>([]);
  const classes = useStyles();

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        
        const response = await require('./temp_news_data.json');
        // const response = await fetch(
        //   `http://127.0.0.1:5000/news-data?t=${new Date().getTime()}`
        // );
        // if (!response.ok) {
        //   throw new Error('Failed to fetch news data');
        // }
        setNewsData(response);
      } catch (error) {
        console.error('Error fetching news data:', error);
      }
    };

    fetchNewsData();
    const intervalId = setInterval(fetchNewsData, 900000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <GeneralCard
      cardStyle={{
        height: '38rem',
        maxWidth: '70em',
        minWidth: '30em',
        padding: '0.5em',
      }}
    >
      <div className={classes.container}>
        <h2 className={classes.header}>Latest News</h2>
        <ul className={classes.list}>
          {newsData.map((item, index) => (
            <li key={index} className={classes.item}>
              <span className={classes.time}>{item.time}</span>
              <a
                className={classes.text}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </GeneralCard>
  );
};

export default NewsComponent;
