import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import useTitleBarSelect, {
  useTitleBarSelectLabel,
} from '../../../../hooks/useTitleBarSelect';
import { useUncachedDataFetch } from '../../../../hooks/useUncachedDataFetch';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Note } from '../../../../sharedInterfaces/notes';
import { mainColors } from '../../../../styling/theme';
import { DatePicker, Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import NotesTable from './NotesTable/NotesTable.component';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material';
import { NoteData, NoteType } from '../../sharedComponents/notes/Notes';

const subjectMap: {
  [key: string]: string;
} = {
  'ucits_ucits-overview': 'UCITS Overview',
  'ucits_ucits-law': 'UCITS Law',
  ucits_exposure: 'UCITS Exposure',
  'ucits_prospectus-restrictions': 'Prospectus Restrictions',
  'ucits_liquidity-overview': 'UCITS Liquidity Overview',
  'ucits_fund-asset-liquidation-time':
    'Liquidity - Fund Asset Liquidation Time',
  ucits_moab: 'Liquidity - MOAB',
  'ucits_rst-fixed': 'Liquidity - RST Fixed',
  'ucits_rst-variable': 'Liquidity - RST Variable',
  'ucits_counterparty-market-liquidity-risk':
    'Counterparty Market Liquidity Risk',
  sif_risk_overview: 'SIF/Raif Overview',
  aifmd_exposure: 'SIF/RAIF Exposure',
};

const NotesPage: React.FC<FundInfoComponentProps> = (props) => {
  const [notesToRender, setNotesToRender] = useState<Note[]>([]);
  const [tableData, setTableData] = useState<NoteData[]>([]);
  const [noteSubjectsToRender, setNoteSubjectsToRender] = useState<
    { label: string; value: string }[]
  >([]);

  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };
  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };

  const dispatch = useDispatch();

  const startDateValue = useAdditionalTitleBarDatePicker(startDatePicker);
  const endDateValue = useAdditionalTitleBarDatePicker(endDatePicker);
  const noteSubjects = useUncachedDataFetch(
    startDateValue && endDateValue
      ? `get_fund_notes/${props.fundId}/${startDateValue}/${endDateValue}`
      : null
  );

  /**
   * Steps.
   * 1. if the fund changes the page will reload
   * 2. If the start date or end date changes, we get new note subjects. This triggers effect 1
   * 3. this sets the noteSubjectsToRender, which will then trigger Effect 2, which sets them in the titlebar
   * 4. if the selected subject changes, we want to wait to see if we have data, and then populate the rules (Effect 3)
   */

  const rule = useTitleBarSelect('rule');
  const subject = useTitleBarSelect('subject_select');
  const subjectLabel = useTitleBarSelectLabel('subject_select');

  const ruleNotesData = useUncachedDataFetch(
    startDateValue && endDateValue && subject && subject !== 'none_selected'
      ? `get_fund_notes/${props.fundId}/${startDateValue}/${endDateValue}/${subject}/all`
      : null
  );

  // EFFECT 1
  // once we have the subjects, add the subjects select to the title bar
  useEffect(() => {
    if (!noteSubjects.isFetching && !noteSubjects.error) {
      if (noteSubjects.data.length > 0) {
        setNoteSubjectsToRender(noteSubjects.data);
      }
    }
  }, [noteSubjects]);

  // Effect 2 - the noteSubjectsToRender changed, so now we're adding the new subjects to the titlebar
  useEffect(() => {
    const valuesToAdd = noteSubjects.data.map((el: string) => ({
      label: subjectMap[el],
      value: el,
    }));
    appendNoteSelectToTitlebar(dispatch, {
      values: valuesToAdd,
      title: 'Select a subject',
      key: 'subject_select',
      currentValue: 'none_selected',
    });

    dispatch(removeTitleBarComponent('rule', 'REMOVE_SELECT_FROM_TITLEBAR'));
    setNotesToRender([]);
    // when the note subjects change, we also want to update the rules
  }, [noteSubjectsToRender]);

  // Effect 3
  useEffect(() => {
    if (
      subject &&
      subject !== 'none_selected' &&
      ruleNotesData &&
      !ruleNotesData.isFetching &&
      !ruleNotesData.error
    ) {
      let valuesToAdd = [{ label: 'All Notes', value: 'all' }].concat(
        ruleNotesData.data
          .map((note: Note) => ({
            label: note.fund_note_topic
              .split('_')
              .map((el: any, index: number) =>
                index === 0 ? el.charAt(0).toUpperCase() + el.slice(1) : el
              )
              .join(' '),
            value: note.fund_note_topic,
          }))
          .sort((a, b) => (a.value > b.value ? 1 : -1))
      );

      // remove any duplicates
      const vals = valuesToAdd.map((el) => el.value);
      valuesToAdd = valuesToAdd.filter(
        ({ value }, index) => !vals.includes(value, index + 1)
      );

      // if there actually are any notes
      if (Object.keys(ruleNotesData.data).length > 0) {
        appendNoteSelectToTitlebar(dispatch, {
          values: valuesToAdd,
          title: 'Rule',
          key: 'rule',
          currentValue: 'all',
        });
      }
      setNotesToRender(ruleNotesData.data);
    } else {
      // we need this because when we change date, subject becomes none_selected again,
      // so we need to get rid of the 'rules'
      dispatch(removeTitleBarComponent('rule', 'REMOVE_SELECT_FROM_TITLEBAR'));
      setNotesToRender([]);
    }
  }, [subject, ruleNotesData]);

  useEffect(() => {
    if (notesToRender.length > 0 && rule) {
      const notesToAddToTheTable =
        rule === 'all'
          ? notesToRender
          : notesToRender.filter((note: any) => note.fund_note_topic === rule);
      setTableData(
        notesToAddToTheTable
          .map((note: any) => {
            return {
              positionDate: note.fund_note_position_date,
              date: note.fund_note_timestamp.split('.')[0],
              noteValue: note.fund_note_text,
              noteType: note.fund_note_text.includes('[{')
                ? 'table'
                : ('basic' as NoteType),
            };
          })
          .sort((a: any, b: any) => {
            if (a.positionDate === b.positionDate) {
              return new Date(a.date).getTime() > new Date(b.date).getTime()
                ? 1
                : -1;
            }
            return a.positionDate > b.positionDate ? 1 : -1;
          })
      );
    } else {
      setTableData([]);
    }
  }, [notesToRender, rule]);
  const theme = useTheme();

  return !subject || subject === 'none_selected' ? (
    <DisplayAreaCenteredWrapper>
      <h1 style={{ color: mainColors.mainBlue }}>
        Please choose a note subject
      </h1>
    </DisplayAreaCenteredWrapper>
  ) : (
    <GeneralComponentErrorShield dataObjects={[ruleNotesData]}>
      {tableData.length > 0 ? (
        <GridItem xs={12} card>
          <NotesTable
            tableData={tableData}
            options={{ search: true }}
            showToolbar
            title={`${
              props.fundName
            }; ${startDateValue} - ${endDateValue}; ${subjectLabel}; ${
              rule
                ? rule
                    .split('_')
                    .map((el, index) =>
                      index === 0
                        ? el.charAt(0).toUpperCase() + el.slice(1)
                        : el
                    )
                    .join(' ')
                : ''
            }`}
          />
        </GridItem>
      ) : (
        <GridItem
          style={{ color: mainColors.mainBlue }}
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <DisplayAreaCenteredWrapper>
            <h2 style={{ color: theme.palette.grey[800] }}>No notes found</h2>
          </DisplayAreaCenteredWrapper>
        </GridItem>
      )}
    </GeneralComponentErrorShield>
  );
};

export default NotesPage;

interface SelectToAddSpec {
  values: {
    value: string;
    label: string;
  }[];
  title: string;
  key: string;
  currentValue: string;
}

function appendNoteSelectToTitlebar(
  dispatch: Dispatch<any>,
  spec: SelectToAddSpec
) {
  dispatch(toggleTitleBarLoading(true));
  const selectsToAdd: Select[] = [
    {
      titleBarTitle: spec.title,
      titleBarKey: spec.key,
      values: spec.values,
      displayOnOverviewPage: false,
      aValueIsAlwaysSelected: false,
      currentValue: spec.currentValue,
    },
  ];
  dispatch(
    addAdditionalTitleBarComponents(selectsToAdd, APPEND_NEW_SELECT_TO_TITLEBAR)
  );
  dispatch(toggleTitleBarLoading(false));
}
