import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import { Status } from '../../../../types/redux/data/dataTypes';
import PageLinkButton from '../../../buttons/PageLinkButton';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import GeneralReportDatePicker from '../../../general/GeneralReportDatePicker';
import { statusSort } from '../../../../utilities/customSorting';
import { specifyStatus } from '../../../../utilities/generalMappings';
import NotesComp from '../../sharedComponents/notes/Notes';

export interface LiquidityOverviewData {
  number: number;
  internalFundName: string;
  externalFundName: string;
  positionDate: string;
  currency: string;
  netAssetValue: number;
  reports: string[];
  liquidationTime: Status;
  moab: Status;
  lrst: Status;
  lrstProspectus: Status;
  lrstCost: Status;
  liquidationTimeLimit: number | null;
  maobLimit: number | null;
  lrstCostLimit: number | null;
  lrstCosetAlertLower: number | null;
  lrstCosetAlertUpper: number | null;
}

interface LiquidityPageLinkButtonProps {
  status: Status;
  fundName: string;
  rowData: LiquidityOverviewData;
  page: string;
  section: string;
  tooltipText?: string;
}

export const handlePageLinkButtonWithSection = (section: string) => {
  return 'rst-fixed';
};

const LiquidityPageLinkButton = (props: LiquidityPageLinkButtonProps) => {
  return (
    <PageLinkButton
      section={props.section}
      page={props.page}
      status={props.status}
      text={props.status}
      fundName={props.fundName}
      tooltipText={props.tooltipText}
    />
  );
};

export const generateLiquidityOverviewColumns = (
  clientName: string | null,
  section: string
): CustomColumn<LiquidityOverviewData>[] => {
  return [
    {
      title: '#',
      field: 'number',
      width: 1,
    },
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      render: (rowData) =>
        clientName === 'mersenne'
          ? 'Fund' + rowData.number
          : rowData.internalFundName,
    },
    {
      title: 'External Fund Name',
      field: 'externalFundName',
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Currency',
      field: 'currency',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      cellStyle: {
        textAlign: 'right',
      },
      headerStyle: {
        textAlign: 'center',
      },
      render: (rowData) => addCommasToNumbersAndRound(rowData.netAssetValue),
    },
    {
      title: 'Daily Reports',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        textAlign: 'center',
      },
      field: 'reports',
      render: (rowData: LiquidityOverviewData) => (
        <GeneralReportDatePicker
          reportNames={rowData.reports}
          reportUrl="ucits_historical_report"
          fundId={rowData.internalFundName}
        />
      ),
    },
    {
      title: 'Liquidation Time',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        width: '10%',
      },
      field: 'liquidationTime',
      pdfRenderType: 'PageLinkButton',
      render: (rowData: LiquidityOverviewData) => (
        <LiquidityPageLinkButton
          section={section}
          rowData={rowData}
          fundName={rowData.internalFundName}
          status={rowData.liquidationTime}
          page="fund-asset-liquidation-time"
          tooltipText={
            rowData.liquidationTimeLimit && clientName !== 'coutts'
              ? `Liquidation Time Limit is 90% in ${rowData.liquidationTimeLimit} business days`
              : undefined
          }
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'liquidationTime'),
    },
    {
      title: 'MOAB',
      field: 'moab',
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        width: '10%',
      },
      render: (rowData: LiquidityOverviewData) => (
        <LiquidityPageLinkButton
          section={section}
          rowData={rowData}
          fundName={rowData.internalFundName}
          status={rowData.moab}
          page="moab"
          tooltipText={
            rowData.maobLimit && clientName !== 'coutts'
              ? `Maximal Achievable One-day Balance for M1 (base) Limit is ${rowData.maobLimit}`
              : undefined
          }
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'moab'),
    },
    clientName !== 'greshamhouse'
      ? (clientName === 'pure' && section !== 'ucits') || section === 'trust'
        ? {}
        : {
            title: `LRST ${
              section.includes('sif')
                ? 'SIF'
                : section.includes('aifmd')
                ? 'AIFMD'
                : section.includes('nurs')
                ? 'NURS'
                : section.includes('act40')
                ? '40 Act'
                : 'UCITS'
            }`,
            pdfRenderType: 'PageLinkButton',
            headerStyle: {
              textAlign: 'center',
            },
            field: 'lrst',
            cellStyle: {
              width: '10%',
            },
            render: (rowData: LiquidityOverviewData) => (
              <LiquidityPageLinkButton
                section={section}
                rowData={rowData}
                status={rowData.lrst}
                fundName={rowData.internalFundName}
                page={handlePageLinkButtonWithSection(section)}
                tooltipText={
                  clientName !== 'coutts'
                    ? `Liquidity Reverse Stress Test ${
                        section.includes('sif') ? 'SIF' : 'UCITS'
                      } Limit is the status of fund's ${
                        section.includes('sif') ? 'SIF' : 'UCITS'
                      } Rules after two 10% of NAV redemptions under super-stressed liquidity`
                    : undefined
                }
              />
            ),
            customSort: (a, b) => statusSort(a, b, 'lrst'),
          }
      : {},
    {
      title: 'LRST Prospectus',
      field: 'lrstProspectus',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        width: '10%',
      },
      pdfRenderType: 'PageLinkButton',
      render: (rowData: LiquidityOverviewData) => (
        <LiquidityPageLinkButton
          rowData={rowData}
          section={section}
          fundName={rowData.internalFundName}
          status={rowData.lrstProspectus}
          page={handlePageLinkButtonWithSection(section)}
          tooltipText={
            clientName !== 'coutts'
              ? "Liquidity Reverse Stress Test Prospectus Limit is the status of fund's Prospectus Rules after two 10% of NAV redemptions under super-stressed liquidity"
              : undefined
          }
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'lrstProspectus'),
    },
    {
      title: 'LRST Cost',
      field: 'lrstCost',
      pdfRenderType: 'PageLinkButton',
      headerStyle: {
        textAlign: 'center',
      },
      cellStyle: {
        width: '10%',
      },
      render: (rowData: LiquidityOverviewData) => (
        <LiquidityPageLinkButton
          rowData={rowData}
          section={section}
          status={rowData.lrstCost}
          fundName={rowData.internalFundName}
          page="rst-variable"
          tooltipText={
            rowData.lrstCostLimit &&
            rowData.lrstCosetAlertLower &&
            rowData.lrstCosetAlertUpper &&
            clientName !== 'coutts'
              ? `Liquidity Reverse Stress Test Cost Limit is a Fail for > ${rowData.lrstCostLimit}% cost after 1 month, Alert for a cost < ${rowData.lrstCosetAlertUpper}% and > ${rowData.lrstCosetAlertLower}% after 1 month, and a Pass otherwise`
              : undefined
          }
        />
      ),
      customSort: (a, b) => statusSort(a, b, 'lrstCost'),
    },
    {
      title: 'Notes',
      field: 'notes',
      width: '5rem',
      render: (rowData: LiquidityOverviewData) => (
        <NotesComp
          fundId={rowData.internalFundName}
          fundName={rowData.externalFundName}
          subject="ucits_liquidity-overview"
          topic="Notes"
          positionDate={rowData.positionDate}
        />
      ),
      cellStyle: {
        padding: '1.6rem',
        textAlign: 'center',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
      headerStyle: {
        textAlign: 'center',
        padding: 'auto 1.6rem',
        paddingRight: '2.4rem',
        paddingLeft: '0.8rem',
      },
    },
  ];
};

const lrstMap = {
  'sif-raif': 'SIF LRST',
  ucits: 'UCITS LRST',
  aifmd: 'AIFMD LRST',
  nurs: 'UCITS LRST',
  act40: 'ACT40 LRST',
};

export const generateLiquidityOverviewData = (
  data: any[],
  reportsList: any[],
  section: 'sif-raif' | 'ucits' | 'aifmd' | 'nurs' | 'act40'
): LiquidityOverviewData[] => {
  // in sif, reportsList is an array containing a single dictionary.
  //Each key (fund) in the dictionary contains an array
  const reportsListMap = reportsList[0];
  return data[0].map((fund: any, index: number) => {
    return {
      number: index + 1,
      internalFundName: fund.fund_name,
      externalFundName: fund.fund_name_full,
      positionDate: fund.monitor_data.selected_position_date,
      currency: fund.monitor_data.base_currency,
      netAssetValue: fund.monitor_data.nav,
      reports: reportsListMap[fund.fund_name],
      liquidationTime: specifyStatus(fund.monitor_data['Liquidation Time']),
      moab: specifyStatus(fund.monitor_data['MOAB']),
      lrst: specifyStatus(fund.monitor_data[lrstMap[section]]),
      lrstProspectus: specifyStatus(
        fund.monitor_data['Prospectus LRST'] ||
          fund.monitor_data['Risk Profile LRST']
      ),
      lrstCost: specifyStatus(fund.monitor_data['Cost LRST']),
      liquidationTimeLimit:
        'Liquidation Time Limit' in fund.monitor_data
          ? fund.monitor_data['Liquidation Time Limit']
          : null,
      maobLimit:
        'MOAB Limit' in fund.monitor_data
          ? fund.monitor_data['MOAB Limit']
          : null,
      lrstCostLimit:
        'LRST Cost Limit' in fund.monitor_data
          ? fund.monitor_data['LRST Cost Limit']
          : null,
      lrstCosetAlertLower:
        'LRST Cost Alert Lower' in fund.monitor_data
          ? fund.monitor_data['LRST Cost Alert Lower']
          : null,
      lrstCosetAlertUpper:
        'LRST Cost Alert Upper' in fund.monitor_data
          ? fund.monitor_data['LRST Cost Alert Upper']
          : null,
    };
  });
};
