import React from 'react';
import { DataObject } from '../../../../../types/redux/data/dataTypes';
import GridItem from '../../../../layout/GridComponents/GridItem';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import { PEPageLinkButton } from '../../peOverview/PeOverview.component';
import CustomTable from '../../../../tables/CustomTable';
import PeLiquidityValueAtRiskBarChart from './PeLiquidityValueAtRiskBarChart.component';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface LVarFundSummaryProps {
  varData: DataObject;
  timeSeriesData: DataObject;
}

interface LVarFundSummaryTableData {
  name: string;
  selectedPositionDate: string;
  nav: number;
  currentDiscountRate: number;
  discountRateVar: number;
  monthlyPortfolioVar: number;
  assetDebtRatio: number;
  assetDebtRatioStatus: string;
  assetDebtRatioLVar: number;
  assetDebtRatioLVarStatus: string;
}

const buildTableData = (inputData: DataObject): LVarFundSummaryTableData[] => {
  if (!inputData.data.length) return [];
  if (!('fund_data' in inputData.data[0])) return [];
  const varData = inputData.data[0].fund_data;
  return [
    {
      name: varData.gm_name,
      selectedPositionDate: varData.Date,
      nav: varData.nav,
      currentDiscountRate: varData.current_discount_factor,
      discountRateVar: varData.L_VaR,
      monthlyPortfolioVar: varData.monthly_VaR,
      assetDebtRatio: varData.asset_debt_ratio,
      assetDebtRatioStatus: varData.asset_debt_ratio_status,
      assetDebtRatioLVar: varData.asset_debt_ratio_LVaR_adj,
      assetDebtRatioLVarStatus: varData.asset_debt_ratio_LVaR_adj_status,
    },
  ];
};

const LVarFundSummaryTableColumns: CustomColumn<LVarFundSummaryTableData>[] = [
  {
    title: 'Fund Name',
    field: 'name',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Selected Position Date',
    field: 'selectedPositionDate',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'NAV',
    field: 'nav',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      addCommasToNumbersAndRound(rowData.nav),
  },
  {
    title: 'Current LIquidity Factor',
    field: 'currentDiscountRate',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      percentageToTwoDecimalPlaces(rowData.currentDiscountRate),
  },

  {
    title: 'Monthly Porfolio Quantile Risk',
    field: 'monthlyPortfolioVar',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.monthlyPortfolioVar),
  },
  {
    title: 'Liquidity Adjusted Quantile Risk',
    field: 'discountRateVar',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.discountRateVar),
  },
  {
    title: 'Asset/Debt ratio',
    field: 'assetDebtRatio',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.assetDebtRatio),
  },
  {
    title: 'Status',
    field: 'assetDebtRatioStatus',
    pdfRenderType: 'PageLinkButton',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) => (
      <PEPageLinkButton status={specifyStatus(rowData.assetDebtRatioStatus)} />
    ),
  },
  {
    title: 'Liquidity Quantile Risk Adjusted Asset Debt Ratio',
    field: 'assetDebtRatioLVarAdjusted',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) =>
      roundNumberToTwoDecimalPlaces(rowData.assetDebtRatioLVar),
  },
  {
    title: 'Status',
    field: 'assetDebtRatioLVarStatus',
    pdfRenderType: 'PageLinkButton',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: LVarFundSummaryTableData) => (
      <PEPageLinkButton
        status={specifyStatus(rowData.assetDebtRatioLVarStatus)}
      />
    ),
  },
];

const LVarFundSummary: React.FC<LVarFundSummaryProps> = ({
  varData,
  timeSeriesData,
}) => {
  
  const tableData = buildTableData(varData);

  return (
    <GridItem card xs={12} style={{ padding: 8 }}>
      <GridItem xs={12}>
        <CustomTable<LVarFundSummaryTableData>
          columns={LVarFundSummaryTableColumns}
          showToolbar
          data={tableData}
          title={'Fund Summary'}
          options={{
            paging: false,
            search: true,
            exportButton: true,
          }}
        />
      </GridItem>
      <GridItem xs={12} style={{ padding: '2rem' }}>
        <PeLiquidityValueAtRiskBarChart
          data={timeSeriesData}
          firstLineKey={1}
          secondLineKey={5}
          formatOption={'percentage'}
          firstValueName={'VaR'}
          secondValueName={'L-VaR'}
        />
      </GridItem>
    </GridItem>
  );
};

export default LVarFundSummary;
