import React, { useMemo } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  SifOverviewData,
  generateSifOverviewColumns,
  generateSifOverviewData,
} from './Overview.data';

interface DeceasedTableProps {
  reportsData: DataObject;
  tableData: DataObject;
}

const DeceasedTable: React.FC<DeceasedTableProps> = (props) => {
  const columns: CustomColumn<SifOverviewData>[] = generateSifOverviewColumns();
  const data = useMemo(
    () => generateSifOverviewData(props.tableData.data[0], props.reportsData),
    [props.tableData, props.reportsData]
  );

  return (
    <GridItem card xs={12}>
      <CustomTable<SifOverviewData>
        //   csvFields={ucitsOverviewCsvFields}
        showToolbar
        toolbarComponents={{
          toolbarTitle: 'Deceased Data',
        }}
        columns={columns}
        data={data}
        options={{
          paging: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, data.length],
          search: true,
          exportButton: true,
          rowStyle: {
            padding: '1rem',
          },
        }}
      />
    </GridItem>
  );
};

export default DeceasedTable;
