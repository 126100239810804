import { Status } from '../../../../../types/redux/data/dataTypes';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToNdecialPlacesNoHundred,
  percentageToTwoDecimalPlaces,
  percentageToTwoDecimalPlacesNoHundred,
} from '../../../../../utilities/numberFormatters';
import GenericStatusCell from '../../../../tables/GenericStatusCell';
import React from 'react';
import { specifyStatus } from '../../../../../utilities/generalMappings';

export interface InvestorBasedData {
  number: number;
  nav: number;
  ucits?: Status;
  sif?: Status;
  act40?: Status;
  prospectus: Status;
  var: number;
  correlation: number;
}

type AreaKey = 'ucits' | 'sif' | 'act40';

export const investorColumns = (
  sectionId: AreaKey
): CustomColumn<InvestorBasedData>[] => [
  {
    title: 'Nav Change',
    field: 'number',
    sorting: false,
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    pdfWidth: 10,
    width: 1,
  },
  {
    title: 'NAV',
    sorting: false,
    field: 'nav',
    render: (rowData: InvestorBasedData) =>
      addCommasToNumbersAndRound(rowData.nav),
    cellStyle: {
      padding: 0,
      paddingLeft: '1rem',
      textAlign: 'center',
      width: '20%',
    },
    headerStyle: {
      textAlign: 'center',
    },
    pdfWidth: 18,
  },
  {
    title:
      sectionId === 'sif' ? 'SIF' : sectionId === 'act40' ? '40 Act' : 'UCITS',
    pdfWidth: 18,
    sorting: false,
    field:
      sectionId === 'sif' ? 'sif' : sectionId === 'act40' ? 'act40' : 'ucits',
    cellStyle: {
      padding: 0,
      borderRight: '1px solid white',
      textAlign: 'center',
      width: '15%',
    },
    pdfRenderType: 'PageLinkButton',
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData: InvestorBasedData) => (
      <GenericStatusCell
        height={'4rem'}
        status={specifyStatus(rowData[sectionId]!)}
      />
    ),
  },
  {
    title: 'Prospectus',
    pdfWidth: 18,
    sorting: false,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      padding: 0,
      textAlign: 'center',
      width: '15%',
    },
    pdfRenderType: 'PageLinkButton',
    field: 'prospectus',
    render: (rowData: InvestorBasedData) => (
      <GenericStatusCell
        height={'4rem'}
        status={specifyStatus(rowData.prospectus)}
      />
    ),
  },
  {
    title: 'VaR',
    pdfWidth: 18,
    sorting: false,
    cellStyle: {
      padding: 0,
      paddingLeft: '1rem',
      textAlign: 'center',
      width: '10%',
    },
    headerStyle: {
      textAlign: 'center',
    },
    field: 'var',
    render: (rowData: InvestorBasedData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.var * 100),
  },
  {
    title: 'Correlation (ex-ante / ex-post)',
    pdfWidth: 15,
    width: '20%',
    sorting: false,
    headerStyle: {
      textAlign: 'center',
    },
    cellStyle: {
      padding: 0,
      textAlign: 'center',
    },
    field: 'correlation',
    render: (rowData: InvestorBasedData) =>
      percentageToTwoDecimalPlaces(rowData.correlation),
  },
];

export type RedemptionLiquidityKeys =
  | 'biz3'
  | 'biz10'
  | 'stress3'
  | 'stress10'
  | 'sov3'
  | 'sov10';

const redemptionMap = {
  biz3: 'redemption_liquidity_stress_test_3',
  biz10: 'redemption_liquidity_stress_test_10',
  stress3: 'redemption_liquidity_stress_test_3_ss',
  stress10: 'redemption_liquidity_stress_test_10_ss',
  sov3: 'redemption_liquidity_stress_test_3_eu',
  sov10: 'redemption_liquidity_stress_test_10_eu',
};

export function buildData(
  data: any,
  key: RedemptionLiquidityKeys,
  sectionId: AreaKey,
  redemptionLevel: number | null
) {
  if (!data.length) return [];

  try {
    const inputKey = redemptionMap[key];
    const dataForConsumption = data[0][inputKey];

    const returnData: any[] = [];

    for (let i = 1; i < dataForConsumption[`${sectionId}_list`].length; i++) {
      returnData.push({
        number: redemptionLevel
          ? percentageToNdecialPlacesNoHundred(
              (i - 1) * redemptionLevel * -1,
              0
            )
          : i - 1,
        nav: dataForConsumption[`${sectionId}_list`][i][1],
        [sectionId]: dataForConsumption[`${sectionId}_list`][i][2],
        prospectus: dataForConsumption.risk_list[i][2],
        var: dataForConsumption.dynamic_list[i][2] / 100,
        correlation: dataForConsumption.dynamic_list[i][3],
      });
    }

    return returnData;
  } catch (error) {
    console.error(error);
    return [];
  }
}
