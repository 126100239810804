import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../../styling/theme';
import { percentageToNdecialPlaces } from '../../../../../utilities/numberFormatters';
import usePngFromRecharts from '../../../../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../redux/pdfExport/actions';
import FileSaver from 'file-saver';
import { PdfComponentType } from '../../../../../types/redux/pdfExports/pdfExportsStore';
import ExportButton from '../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../buttons/ChartDownloadButton';
import clsx from 'clsx';
import { hexToRGBA } from '../../../../../utilities/colorUtilities';

interface PerformanceDiagnosticsGraphProps {
  data: any;
}

interface PerformanceDiagnosticsGraphData {
  date: string;
  fundPerformance: number;
  shareClassPerformance: number;
  difference: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  buttonContainer: {
    display: 'flex',
    gap: '1.5rem',
    marginRight: '2rem',
  },
  toggleButton: {
    all: 'unset',
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.2rem 2rem',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  activeButton: {
    backgroundColor: mainColors.mainBlue,
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '25rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipFund: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
  },
  tooltipShareClass: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.Fail,
  },
  tooltipDifference: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.pastelBlue,
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          <div className={classes.tooltipFund}>
            Calculated Returns:{' '}
            {percentageToNdecialPlaces(
              payload.find((item: any) => item.name === 'fundPerformance')
                .value / 100,
              2
            )}
          </div>
          <div className={classes.tooltipShareClass}>
            Realised:{' '}
            {percentageToNdecialPlaces(
              payload.find((item: any) => item.name === 'shareClassPerformance')
                .value / 100,
              2
            )}
          </div>
          <div className={classes.tooltipDifference}>
            Difference:{' '}
            {percentageToNdecialPlaces(
              payload.find((item: any) => item.name === 'difference').value /
                100,
              2
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildGraphData = (
  inputData: any,
  graphType: 'daily' | 'cumulative'
): PerformanceDiagnosticsGraphData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: PerformanceDiagnosticsGraphData[] = [];
    inputData.data[0].fund_vs_share_class.map((date: any) => {
      returnData.push({
        date: date.Date,
        fundPerformance:
          graphType === 'daily' ? date.daily_pl_fund : date.running_pl_fund,
        shareClassPerformance:
          graphType === 'daily' ? date.daily_pl_sc : date.running_pl_sc,
        difference:
          graphType === 'daily'
            ? date.difference_dailypl
            : date.difference_cumulativepl,
      });
    });
    return returnData.sort(
      (
        a: PerformanceDiagnosticsGraphData,
        b: PerformanceDiagnosticsGraphData
      ) =>
        a.date.localeCompare(b.date, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    );
  }
};

const PerformanceDiagnosticsGraph: React.FC<
  PerformanceDiagnosticsGraphProps
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [graphType, setGraphType] = useState<'daily' | 'cumulative'>(
    'cumulative'
  );
  const builtGraphData = buildGraphData(props.data, graphType);

  const id = 'performance_diagnostics_graph';
  const title = 'Performance Diagnostics - Graph';

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} card cardStyle={{ height: '100%' }}>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonContainer}>
          <button
            onClick={() => {
              setGraphType('cumulative');
            }}
            className={
              graphType === 'cumulative'
                ? clsx(classes.toggleButton, classes.activeButton)
                : classes.toggleButton
            }
          >
            Cumulative
          </button>
          <button
            className={
              graphType === 'daily'
                ? clsx(classes.toggleButton, classes.activeButton)
                : classes.toggleButton
            }
            onClick={() => {
              setGraphType('daily');
            }}
          >
            Daily
          </button>
        </div>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtGraphData}
            fields={Object.keys(builtGraphData[0])}
            fieldsMap={[
              { key: 'date', label: 'Date' },
              { key: 'fundPerformance', label: 'Calculated Returns' },
              { key: 'shareClassPerformance', label: 'Realised Returns' },
            ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={560}>
        <LineChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 1);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={
                graphType === 'daily'
                  ? 'Daily Movement %'
                  : 'Cumulative Movement %'
              }
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Line
            type="linear"
            dataKey="fundPerformance"
            stroke={mainColors.mainBlue}
            strokeWidth={2}
          />
          <Line
            type="linear"
            dataKey="shareClassPerformance"
            stroke={mainColors.Fail}
            strokeWidth={2}
          />
          <Line
            type="linear"
            dataKey="difference"
            stroke={mainColors.pastelBlue}
            strokeWidth={2}
          />
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
            formatter={(item) => {
              switch (item) {
                case 'fundPerformance':
                  return 'Calculated Returns';
                case 'shareClassPerformance':
                  return 'Realised Returns';
                case 'difference':
                  return 'Difference';
                default:
                  return item;
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default PerformanceDiagnosticsGraph;
