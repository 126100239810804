import React, { useEffect } from 'react';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GridItem from '../../../layout/GridComponents/GridItem';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { greys, mainColors } from '../../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { hexToRGBA } from '../../../../utilities/colorUtilities';
import ExportButton from '../../../feedback/ExportButton';
import {
  addCommasToNumbersAndRound,
  formatMillions,
} from '../../../../utilities/numberFormatters';
import ChartDownloadButton from '../../../buttons/ChartDownloadButton';
import { useDispatch } from 'react-redux';
import usePngFromRecharts from '../../../../hooks/usePngFromRecharts';
import { addComponentToPdfExport } from '../../../../redux/pdfExport/actions';
import { PdfComponentType } from '../../../../types/redux/pdfExports/pdfExportsStore';
import FileSaver from 'file-saver';

interface DiscountCashflowOverviewChartProps {
  data: DataObject;
  selectedPosition: string;
}

interface DiscountCashflowOverviewChartData {
  year: number;
  free_cash_flow: number;
  discounted_free_cash_flow: number;
  ebit: number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    width: '95%',
    marginLeft: '3rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  buttonGroupContainer: {
    display: 'flex',
    gap: '0.2rem',
    margin: '0 2rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '40rem',
    color: mainColors.mainBlue,
  },
  tooltipAsset: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipValue: {
    fontSize: '1.6rem',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
  },
  tooltipValueBelowZero: {
    color: mainColors.Fail,
  },
  toggleButton: {
    all: 'unset',
    transition: 'width .2s',
    borderRadius: '.8rem',
    padding: '0.2rem 2rem',
    fontSize: 'clamp(1rem, 0.9vw, 1.5rem)',
    backgroundColor: greys.grey400,
    height: '3rem',
    color: 'white',
    fontWeight: 500,
    cursor: 'pointer',
    filter: `drop-shadow(0.1rem 0.1rem 0.1rem ${greys.grey400})`,
  },
  toggleButtonBlue: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.mainBlue, 0.5),
    },
  },
  toggleButtonGreen: {
    '&:hover': {
      backgroundColor: hexToRGBA(mainColors.Pass, 0.5),
    },
  },
  activeButtonBlue: {
    backgroundColor: mainColors.mainBlue,
  },
  activeButtonGreen: {
    backgroundColor: mainColors.Pass,
  },
  monoSpace: {
    fontFamily: 'monospace',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();

  if (active && payload && payload.length) {
    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipAsset}>Year: {label}</div>
          <hr />
          <div
            className={classes.tooltipValue}
            style={{ color: mainColors.Fail }}
          >
            <div>EBIT:</div>
            <div className={classes.monoSpace}>
              {addCommasToNumbersAndRound(payload[0].payload.ebit)}
            </div>
          </div>
          <div
            className={classes.tooltipValue}
            style={{ color: mainColors.Pass }}
          >
            <div>Discounted Free Cash Flow:</div>
            <div className={classes.monoSpace}>
              {addCommasToNumbersAndRound(
                payload[0].payload.discounted_free_cash_flow
              )}
            </div>
          </div>
          <div
            className={classes.tooltipValue}
            style={{ color: mainColors.fourthPurple }}
          >
            <div>Free Cash Flow:</div>
            <div className={classes.monoSpace}>
              {addCommasToNumbersAndRound(payload[0].payload.free_cash_flow)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildChartData = (
  data: DataObject,
  selectedPosition: string
): DiscountCashflowOverviewChartData[] => {
  if (
    !data ||
    !data.data ||
    !data.data[0] ||
    !data.data[0][selectedPosition] ||
    !data.data[0][selectedPosition].timeseries
  )
    return [];
  if (!data.data.length) return [];

  const timeSeries = data.data[0][selectedPosition].timeseries;

  const chartData: DiscountCashflowOverviewChartData[] = [];
  for (let i = 0; i < 10; i++) {
    chartData.push({
      year: i,
      free_cash_flow: timeSeries['Free Cash Flow'][i],
      discounted_free_cash_flow: timeSeries['Discounted Free Cash Flow'][i],
      ebit: timeSeries['EBIT'][i],
    });
  }
  return chartData;
};

const DiscountCashflowOverviewChart: React.FC<
  DiscountCashflowOverviewChartProps
> = ({ data, selectedPosition }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const chartData = buildChartData(data, selectedPosition);
  const title = `Discount Cashflow - Portfolio`;

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: 'pe_discount_cashflow_chart',
        handler: handleDownload,
        type: PdfComponentType.BAR_CHART,
        title: title,
        dontMoveToNewPage: true,
        specificYPosition: undefined,
        specificXPosition: undefined,
        alignment: 'center',
        dontIncrementYPosition: undefined,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, title + '.png');
    }
  };

  return (
    <GridItem
      xs={9}
      card
      style={{
        padding: 8,
        height: '100%',
        maxHeight: '50rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      cardStyle={{ minHeight: 700 }}
    >
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.buttonGroupContainer}>
          <ExportButton
            fields={chartData.length ? Object.keys(chartData[0]) : []}
            exportData={chartData}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%">
        <BarChart
          width={500}
          height={200}
          data={chartData}
          margin={{
            top: 20,
            right: 50,
            left: 30,
            bottom: 100,
          }}
          ref={ref}
        >
          <CartesianGrid strokeDasharray="5 5" vertical={false} />
          <XAxis
            dataKey="year"
            interval={0}
            angle={0}
            textAnchor="end"
            height={70}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
                height: '0.1rem',
              }}
              value={'Year'}
              position={'insideBottom'}
            />
          </XAxis>
          <YAxis
            width={100}
            tickFormatter={(tickItem) => {
              return formatMillions(tickItem);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'Amount'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Legend
            verticalAlign="top"
            height={30}
            align="center"
            formatter={(value: any) => {
              if (value === 'discounted_free_cash_flow') {
                return 'Discounted Free Cash Flow';
              } else if (value === 'free_cash_flow') {
                return 'Free Cash Flow';
              } else if (value === 'ebit') {
                return 'EBIT';
              }
              return value;
            }}
            iconSize={20}
          />
          <ReferenceLine y={0} stroke={greys.grey600} />
          <Bar dataKey={'ebit'} fill={mainColors.Fail} />
          <Bar dataKey={'discounted_free_cash_flow'} fill={mainColors.Pass} />
          <Bar dataKey={'free_cash_flow'} fill={mainColors.fourthPurple} />
        </BarChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default DiscountCashflowOverviewChart;
