import React, { CSSProperties } from 'react';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { DataObject, Status } from '../../../../types/redux/data/dataTypes';
import { addCommasToNumbersAndRound } from '../../../../utilities/numberFormatters';
import GeneralReportDatePicker from '../../../general/GeneralReportDatePicker';
import ReportsLinkButton from '../../aifmd/overview/table/components/ReportsLinkButton/reportsLinkButton.component';
import CalendarPopup from '../../sharedComponents/overview/components/Calendar.component';
import { mainColors } from '../../../../styling/theme';
import { specifyStatus } from '../../../../utilities/generalMappings';
import NotesComp from '../../sharedComponents/notes/Notes';

export interface SifOverviewData {
  index: number;
  internalFundName: string;
  fundName: string;
  positionDate: string;
  positionExposure: string[][];
  currency: string;
  netAssetValue: number;
  reports: string[];
  leverage: Status;
  timeSeriesData: (string | number)[][];
  derogationMessage?: string;
  cssfRiskSpreading: Status;
  historicalTimeSeriesStatus: Status;
  prospectusRestrictions: Status;
  countryExposure: string[][];
}

const DetailLine = (props: {
  initialText: string;
  detailLine?: string;
  detailStyle: CSSProperties;
}) => {
  return (
    <>
      <p style={{ margin: 0 }}>{props.initialText}</p>
      {props.detailLine && <p style={props.detailStyle}>{props.detailLine}</p>}
    </>
  );
};

export const generateSifOverviewColumns =
  (): CustomColumn<SifOverviewData>[] => {
    return [
      {
        field: 'index',
        title: '#',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        width: '2%',
      },
      {
        field: 'internalFundName',
        title: 'Fund ID',
        headerStyle: {},
        cellStyle: {},
        render: (rowData) =>
          rowData.fundName.includes('Mersenne')
            ? 'Fund' + (rowData.index + 1)
            : rowData.internalFundName,
      },
      {
        field: 'fundName',
        title: 'Name',
        headerStyle: {},
        cellStyle: {},
        render: (rowData) => {
          return (
            <DetailLine
              initialText={rowData.fundName}
              detailLine={rowData.derogationMessage}
              detailStyle={{ margin: 0, color: mainColors.Fail }}
            />
          );
        },
      },
      {
        field: 'positionDate',
        title: 'Position Date',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: SifOverviewData) => (
          <CalendarPopup
            date={rowData.positionDate}
            fundId={rowData.internalFundName}
          />
        ),
      },
      {
        hidden: true,
        field: 'derogationMessage',
        searchable: true,
      },
      {
        field: 'currency',
        title: 'Currency',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'netAssetValue',
        title: 'Net Asset Value',
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'right',
        },
        render: (rowData) => addCommasToNumbersAndRound(rowData.netAssetValue),
      },
      {
        field: 'reports',
        title: 'Reports',
        render: (rowData: SifOverviewData) => (
          <GeneralReportDatePicker
            reportNames={rowData.reports}
            reportUrl="ucits_historical_report"
            fundId={rowData.internalFundName}
          />
        ),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        width: '10%',
      },
      {
        field: 'leverage',
        title: 'Leverage',
        width: '20rem',
        render: (rowData: SifOverviewData) => {
          return (
            <ReportsLinkButton
              status={specifyStatus(rowData.leverage)}
              section="sif-raif"
              page="exposure"
              fundName={rowData.internalFundName}
            />
          );
        },
        pdfRenderType: 'PageLinkButton',
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'cssfRiskSpreading',
        title: 'CSSF Risk Spreading',
        width: '20rem',
        pdfRenderType: 'PageLinkButton',
        render: (rowData: SifOverviewData) => {
          return (
            <ReportsLinkButton
              section="sif-raif"
              page="cssf-risk-spreading"
              status={specifyStatus(rowData.cssfRiskSpreading)}
              fundName={rowData.internalFundName}
            />
          );
        },
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'prospectusRestrictions',
        title: 'Prospectus Restrictions',
        pdfRenderType: 'PageLinkButton',
        width: '20rem',
        render: (rowData: SifOverviewData) => {
          return (
            <ReportsLinkButton
              status={specifyStatus(rowData.prospectusRestrictions)}
              fundName={rowData.internalFundName}
              section="sif-raif"
              page="prospectus-restrictions"
            />
          );
        },
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: 'Notes',
        field: 'notes',
        width: '5rem',
        render: (rowData: SifOverviewData) => (
          <NotesComp
            fundId={rowData.internalFundName}
            fundName={rowData.fundName}
            subject="sif_risk_overview"
            topic="Notes"
            positionDate={rowData.positionDate}
          />
        ),
        cellStyle: {
          padding: '1.6rem',
          textAlign: 'center',
          paddingRight: '2.4rem',
          paddingLeft: '0.8rem',
        },
        headerStyle: {
          textAlign: 'center',
          padding: 'auto 1.6rem',
          paddingRight: '2.4rem',
          paddingLeft: '0.8rem',
        },
      },
    ];
  };

export const generateSifOverviewData = (
  data: any[],
  sifReports: DataObject
) => {
  if (!data) {
    return [];
  }

  const tableData = data.map((fund: any, index: number) => {
    return {
      index: index + 1,
      internalFundName: fund.fund_name,
      fundName: fund.fund_name_full,
      positionDate: fund.monitor_data.selected_position_date,
      currency: fund.monitor_data.base_currency,
      countryExposure: fund.monitor_data.country_exposure,
      netAssetValue: fund.monitor_data.nav,
      positionExposure: fund.monitor_data.position_exposure,
      derogationMessage:
        fund.monitor_data.six_month_derogation &&
        fund.monitor_data.six_month_derogation.result === 'live'
          ? `Derogation Expiry Date: ${fund.monitor_data.six_month_derogation.derogation_date}`
          : undefined,
      timeSeriesData: fund.monitor_data.historical_time_series,
      historicalTimeSeriesStatus: specifyStatus(
        fund.monitor_data.historical_time_series_status
      ),
      reports: sifReports.data[0][fund.fund_name],
      leverage: specifyStatus(fund.monitor_data.Leverage),
      cssfRiskSpreading: specifyStatus(fund.monitor_data['Risk Spreading']),
      prospectusRestrictions: specifyStatus(
        fund.monitor_data['Risk_Compliance']
      ),
    };
  });
  return tableData;
};
