import { ReactElement } from 'react';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlacesNoHundred,
  roundNumberToTwoDecimalPlaces,
} from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { PEPageLinkButton } from '../../peOverview/PeOverview.component';
import { Tooltip } from '@mui/material';
import { specifyStatus } from '../../../../../utilities/generalMappings';

interface IAssetDebtRatiorData {
  scenario: string;
  status: string;
  value: number;
  tooltip: string;
}

interface IOverallData {
  nav: number;
  latestLvar: number;
  latestVar: number;
  positionDate: string;
}

interface Props {
  data: any;
  height: number | string;
}

export const OverallDataColumns: CustomColumn<IOverallData>[] = [
  {
    title: 'NAV',
    field: 'nav',
    render: (rowData) => addCommasToNumbersAndRound(rowData.nav),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'VaR',
    field: 'latestVar',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.latestVar),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Liquidity VaR',
    field: 'latestLvar',
    render: (rowData) =>
      percentageToTwoDecimalPlacesNoHundred(rowData.latestLvar),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Position date',
    field: 'positionDate',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
];

export const AssetDebtRatioColumns: CustomColumn<IAssetDebtRatiorData>[] = [
  {
    title: 'Scenario',
    field: 'scenario',
    render: (rowData) => (
      <Tooltip title={rowData.tooltip} placement="top">
        <div>{rowData.scenario}</div>
      </Tooltip>
    ),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: IAssetDebtRatiorData) => (
      <PEPageLinkButton status={specifyStatus(rowData.status)} />
    ),
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
  },
  {
    title: 'Value',
    field: 'value',
    cellStyle: {
      textAlign: 'center',
    },
    headerStyle: {
      textAlign: 'center',
    },
    render: (rowData) => roundNumberToTwoDecimalPlaces(rowData.value),
  },
];

function buildOverallTableData(inputData: any) {
  if (!inputData.data.length) return [];
  const overallData = inputData.data[0];
  return [
    {
      nav: overallData.nav,
      latestLvar: overallData.latest_LVaR,
      latestVar: overallData.latest_VaR,
      positionDate: overallData.selected_position_date,
    },
  ];
}

export function buildAssetDebtRatioTableData(inputData: any) {
  
  if (!inputData.data.length) return [];
  const AssetDebtRatioData = inputData.data[0];

  return [
    {
      scenario: 'Asset/Debt Ratio',
      status: AssetDebtRatioData.asset_debt_ratio.status,
      value: AssetDebtRatioData.asset_debt_ratio.value,
      tooltip: AssetDebtRatioData.asset_debt_ratio.tooltip,
    },
    {
      scenario: 'VaR Adjusted Asset/Debt Ratio',
      status: AssetDebtRatioData.asset_debt_ratio_VaR_adj.status,
      value: AssetDebtRatioData.asset_debt_ratio_VaR_adj.value,
      tooltip: AssetDebtRatioData.asset_debt_ratio_VaR_adj.tooltip,
    },
    {
      scenario: 'L-VaR Adjusted Asset/Debt Ratio',
      status: AssetDebtRatioData.asset_debt_ratio_LVaR_adj.status,
      value: AssetDebtRatioData.asset_debt_ratio_LVaR_adj.value,
      tooltip: AssetDebtRatioData.asset_debt_ratio_LVaR_adj.tooltip,
    },
  ];
}

function AssetDebtRatioTable({ data, height }: Props): ReactElement | null {

  const tableData = buildAssetDebtRatioTableData(data);
  const overallTableData = buildOverallTableData(data);

  return (
    <GridItem xs={5} card cardStyle={{ minHeight: height }}>
      <div style={{ marginBottom: '3rem' }}>
        <CustomTable<IOverallData>
          showToolbar={false}
          options={{
            paging: false,
            sorting: false,
          }}
          data={overallTableData}
          columns={OverallDataColumns}
        />
      </div>
      <CustomTable<IAssetDebtRatiorData>
        showToolbar={false}
        options={{
          paging: false,
          sorting: false,
        }}
        data={tableData}
        columns={AssetDebtRatioColumns}
      />
    </GridItem>
  );
}

export default AssetDebtRatioTable;
