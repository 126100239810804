import { ReactElement } from 'react';
import { addCommasToNumbersAndRound } from '../../../../../utilities/numberFormatters';
import GridItem from '../../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../../layout/utilities/displayAreaWrapper';
import { Map } from '../../../sharedComponents/overview/components/DetailMap.component';
import { NonCisComponentProps } from '../table/NonCisTable.component';
import { Grid, useTheme } from '@mui/material';

function generateNonCisMapData(
  inputData: any[],
  selectedPage: 'grossCountryExposure' | 'netCountryExposure'
) {
  if (!inputData.length) return [];
  try {
    const data = inputData[0].noncis_data;
    const nav = inputData[0].nav;
    const returnObj: any = {};

    // if it's gross, you have to absolute val each value as it goes in
    data.forEach((cisDatum: any) => {
      const valToAdd =
        selectedPage === 'grossCountryExposure'
          ? Math.abs(cisDatum.position_exposure)
          : cisDatum.position_exposure;
      if (cisDatum.country_code in returnObj) {
        returnObj[cisDatum.country_code] += valToAdd;
      } else {
        returnObj[cisDatum.country_code] = valToAdd;
      }
    });

    const maxVal = Math.max(...(Object.values(returnObj) as number[]));

    const mapTableData: any[] = Object.keys(returnObj).map((key) => {
      return {
        country: key,
        exposure: returnObj[key],
        pcOfNav: (returnObj[key] * 100) / nav,
      };
    });
    return [returnObj, maxVal, mapTableData];
  } catch (err) {
    console.error('There was a problem generating the map data: ', err);
    return [];
  }
}

function NonCisMaps({ inputData }: NonCisComponentProps): ReactElement {
  const grossData = generateNonCisMapData(
    inputData.data,
    'grossCountryExposure'
  );

  const theme = useTheme();
  const netData = generateNonCisMapData(inputData.data, 'netCountryExposure');

  return (
    <Grid
      container
      style={{
        margin: 0,
      }}
      spacing={2}
    >
      {netData.length > 0 && grossData.length > 0 ? (
        [
          [netData, 'Net Exposure'],
          [grossData, 'Gross Exposure'],
        ].map((el: any) => {
          return (
            <GridItem
              style={{
                alignSelf: 'stretch',
              }}
              card
              xs={12}
              lg={6}
              key={el[1]}
              cardStyle={{ padding: '2.4rem', height: '100%' }}
            >
              <Map
                title={el[1]}
                tooltipLabelFormatter={(val: string | number) => val as string}
                tooltipValueFormatter={(val: string | number) =>
                  addCommasToNumbersAndRound(val)
                }
                val={el[0][1]}
                data={el[0][0]}
              />
            </GridItem>
          );
        })
      ) : (
        <DisplayAreaCenteredWrapper>
          <h1 style={{ color: theme.palette.primary.main }}>
            No data to display
          </h1>
        </DisplayAreaCenteredWrapper>
      )}
    </Grid>
  );
}

export default NonCisMaps;
