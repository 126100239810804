import countryCodesToNames from './countryCodesToNames.json';
import { Status } from "../types/redux/data/dataTypes";

// convert given country code to name
export const countryCodeToName = (code: string): string => {
  const mapping: any = countryCodesToNames;
  return mapping[code] ?? code;
};

export const currency_symbols_map: { [index: string]: string } = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  CHF: '₣', // Swiss Franc
  SEK: 'kr', // Swedish Krona
  DKK: 'kr.', // Danish Krone
  NOK: 'kr', // Norwegian Krone
};

export function specifyStatus(input: string): Status {
  if (!input || typeof input !== 'string') return Status.NA;
  switch (input.toLowerCase()) {
    case 'pass - alert':
    case 'Pass - Alert':
    case 'Pass-Alert':
    case 'pass-alert':
    case 'alert':
      return Status.Alert;
    case 'pass':
    case 'Pass':
      return Status.Pass;
    case 'fail':
    case 'Fail':
      return Status.Fail;
    case 'n/a':
    case 'N/A':
    case 'na':
      return Status.NA;
    case 'altered position':
      return Status.alteredPosition;
    case 'no change':
      return Status.noChange;
    case 'new position':
      return Status.newPosition;
    case 'multiple positions':
      return Status.multiplePositions;
    case 'removed position':
      return Status.removedPosition;
    default:
      return Status.Fail;
  }
}