import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect } from 'react';
import { greys, mainColors } from '../../../../../styling/theme';
import requestClient from '../../../../../utilities/requestClient';
import TokenService from '../../../../services/token-service';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { userIdSelector } from '../../../../../redux/auth/selectors';
import DeleteUserConfirmation from './DeleteUserConfirmation.component';
import UserRoleConfirmation from './UserRoleConfirmation.component';
import { roleTranslator } from './../ManageAccounts.component';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  userListContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.4rem',
  },
  filtersBar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem 0',
  },
  userList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  userItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderBottom: `1px solid ${greys.grey100}`,
  },
  columnHeaders: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    border: `1px solid ${greys.grey100}`,
  },
  columnHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    fontWeight: 600,
    fontSize: '1.6rem',
    color: mainColors.mainBlue,
    minWidth: '10rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  columnHeader_Icon: {
    width: '5rem',
  },
  columnHeader_Actions: {
    width: '10rem',
  },
  userColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    padding: '0.5rem 1rem',
    fontSize: '1.5rem',
    color: mainColors.mainBlue,
    fontWeight: 400,
    minWidth: '10rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userColumn_Icon: {
    width: '5rem',
    justifyContent: 'center',
  },
  userColumn_Actions: {
    width: '10rem',
  },
  userIcon: {
    height: '4rem',
    width: '4rem',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: mainColors.mainBlue,
    fontSize: '1.4rem',
    fontWeight: 600,
    border: `1px solid ${mainColors.mainBlue}`,
    userSelect: 'none',
    backgroundColor: greys.grey100,
  },
  activeUserTag: {
    fontSize: '1rem',
    fontWeight: 600,
    color: 'white',
    backgroundColor: mainColors.Pass,
    padding: '0.2rem 0.5rem',
    borderRadius: '0.4rem',
  },
  userActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  deleteUserButton: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    border: `1px solid ${mainColors.Fail}`,
    color: mainColors.Fail,
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '1.6rem',
    },
    '&:hover': {
      backgroundColor: mainColors.Fail,
      color: 'white',
    },
    '&:active': {
      backgroundColor: mainColors.Fail_darker,
      color: 'white',
    },
  },
  resetPasswordButton: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 400,
    border: `1px solid ${mainColors.mainBlue}`,
    color: mainColors.mainBlue,
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '1.6rem',
    },
    '&:hover': {
      backgroundColor: mainColors.mainBlue,
      color: 'white',
    },
    '&:active': {
      backgroundColor: mainColors.mainBlue_Darker,
      color: 'white',
    },
  },
  userCounter: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    fontWeight: 500,
    fontSize: '1.4rem',
    border: `1px solid ${mainColors.mainBlue}`,
    color: mainColors.mainBlue,
  },
  userCounterTitle: {
    fontWeight: 600,
  },
  userCounterCount: {
    fontWeight: 400,
  },
}));

// [2024-08-27, Tom Walsh] - Temporary code to remove '_funds' accounts from the list after corresponding deactivation date
// Deactivation dates for accounts, deafault is end of october 2024
export const AccountDeactivationDates:  {[index: string]: string} = {
  'pure_funds': '2024-10-31',
  'gemini_funds': '2024-10-31',
  'epic_funds': '2024-10-31',
  'gem_funds': '2024-10-31',
  'snif_funds': '2024-10-31',
  'trium_funds': '2024-10-31',
  'quayside_funds': '2024-10-31',
  'greshamhouse_funds': '2024-10-31',
  'corum_butler_funds': '2024-10-31',
  'bflexion_funds': '2024-10-31',
  'wcapital_funds': '2024-10-31',
};
// [2024-08-27, Tom Walsh] - End of temporary code

type NumberOfAccounts = {
  current_number_of_users: number;
  maximum_allowed_users: number;
};

const UserList: FC = () => {
  const classes = useStyles();
  const client = requestClient();

  const userName = useSelector(userIdSelector);

  const [users, setUsers] = React.useState<any[]>([]);
  const [numberOfAccounts, setNumberOfAccounts] =
    React.useState<NumberOfAccounts | null>();

  const getUsers = () => {
    client
      .get('raptor/read_subsidiary_user', {
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then((response) => {
        const ordered = response.data.reduce((a: any, c: any) => {
          if (c.user_name === userName) a.unshift(c);
          else a.push(c);
          return a;
        }, []);
        // setUsers(ordered);
        // [2024-08-27, Tom Walsh] - Temporary code to remove '_funds' accounts from the list after corresponding deactivation date
        const currentDate = dayjs();
        const filtered = ordered.filter((user: any) => {
          if (
            user.user_name in AccountDeactivationDates &&
            dayjs(AccountDeactivationDates[user.user_name]) <= currentDate
          ) {
            return false;
          }
          return true;
        });
        setUsers(filtered);
        // [2024-08-27, Tom Walsh] - End of temporary code
      });
  };

  const getNumberOfAccounts = () => {
    client
      .get('raptor/settings/authorization/max_create_account_status', {
        headers: {
          Authorization: 'Bearer ' + TokenService.getLocalAccessToken(),
        },
      })
      .then((response) => {
        setNumberOfAccounts(response.data);
      });
  };

  useEffect(() => {
    getUsers();
    getNumberOfAccounts();
  }, []);

  return (
    <div className={classes.userListContainer}>
      <div className={classes.filtersBar}>
        <div className={classes.userCounter}>
          <div className={classes.userCounterTitle}>Accounts:</div>
          {numberOfAccounts ? (
            <div className={classes.userCounterCount}>
              {numberOfAccounts.current_number_of_users} /{' '}
              {numberOfAccounts.maximum_allowed_users}
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.columnHeaders}>
        <div
          className={clsx(classes.columnHeader, classes.columnHeader_Icon)}
        ></div>
        <div className={classes.columnHeader}>User ID</div>
        <div className={classes.columnHeader}>Email</div>
        <div className={classes.columnHeader}>Expiry Date</div>
        <div className={classes.columnHeader}>Role</div>
        <div
          className={clsx(classes.columnHeader, classes.columnHeader_Actions)}
        >
          Actions
        </div>
      </div>
      <div className={classes.userList}>
        {users.map((user, index) => (
          <div key={index} className={classes.userItem}>
            <div className={clsx(classes.userColumn, classes.userColumn_Icon)}>
              <div className={classes.userIcon}>
                {user.user_name.length ? user.user_name[0].toUpperCase() : ''}
              </div>
            </div>
            <div className={classes.userColumn}>
              {user.user_name}
              {user.user_name === userName ? (
                <div className={classes.activeUserTag}>It's You</div>
              ) : null}
            </div>
            <div className={classes.userColumn}>{user.user_email}</div>
            <div className={classes.userColumn}>{user.user_expiry_date}</div>
            <div className={classes.userColumn}>
              {roleTranslator(user.user_type)}
            </div>
            <div
              className={clsx(classes.userColumn, classes.userColumn_Actions)}
            >
              <div className={classes.userActionsContainer}>
                {user.user_name === userName ||
                user.user_type === 'external_administrator' ? null : (
                  <UserRoleConfirmation
                    user={user}
                    reloadUsers={() => getUsers()}
                  />
                )}
                {user.user_name === userName ? null : (
                  <DeleteUserConfirmation
                    user={user}
                    reloadUsers={() => getUsers()}
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserList;
