import React, { useEffect } from 'react';
import GridItem from '../../../../../layout/GridComponents/GridItem';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import makeStyles from '@mui/styles/makeStyles';
import { greys, mainColors } from '../../../../../../styling/theme';
import {
  percentageToNdecialPlaces,
  percentageToNdecialPlacesAddZeros,
} from '../../../../../../utilities/numberFormatters';
import usePngFromRecharts from '../../../../../../hooks/usePngFromRecharts';
import { useDispatch } from 'react-redux';
import { addComponentToPdfExport } from '../../../../../../redux/pdfExport/actions';
import FileSaver from 'file-saver';
import { PdfComponentType } from '../../../../../../types/redux/pdfExports/pdfExportsStore';
import ExportButton from '../../../../../feedback/ExportButton';
import ChartDownloadButton from '../../../../../buttons/ChartDownloadButton';

interface CurrencyVsFxGraphProps {
  data: any;
  currency: string;
}

interface CurrencyVsFxGraphData {
  [index: string]: string | number;
}

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 2.5rem 1rem 1.5rem',
  },
  cardTitle: {
    color: mainColors.mainBlue,
    fontSize: '2.2rem',
    fontWeight: 400,
    margin: '1rem 1.5rem',
  },
  customTooltipContainer: {
    backgroundColor: 'white',
    padding: '1rem 1.5rem',
    borderRadius: '0.5rem',
    border: `1px solid ${mainColors.mainBlue}`,
    width: '25rem',
    color: mainColors.mainBlue,
  },
  tooltipDate: {
    fontSize: '2rem',
    fontWeight: 500,
  },
  tooltipItem: {
    fontSize: '1.6rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    display: 'flex',
    justifyContent: 'space-between',
  },
  exportButtons: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomTooltip = ({ active, payload, label }: any) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    type valueType = {
      name: string;
      value: number;
    };
    const values: valueType[] = payload.map((item: any) => {
      return {
        name: item.name,
        value: item.value,
      };
    });
    const sortedValues = values.sort(
      (a: valueType, b: valueType) => b.value - a.value
    );

    return (
      <div className={classes.customTooltipContainer}>
        <div>
          <div className={classes.tooltipDate}>{label}</div>
          <hr />
          {sortedValues.map((item: valueType) => {
            return (
              <div
                className={classes.tooltipItem}
                key={item.name}
                style={{
                  color: item.name.includes('differences')
                    ? mainColors.pastelBlue
                    : item.name.includes('_fx')
                    ? mainColors.Pass
                    : mainColors.mainBlue,
                }}
              >
                <div>{item.name}</div>
                <div>
                  {percentageToNdecialPlacesAddZeros(item.value / 100, 2)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

const buildGraphData = (
  inputData: any,
  currency: string
): CurrencyVsFxGraphData[] => {
  if (!inputData.data || !inputData.data.length) {
    return [];
  } else {
    const returnData: CurrencyVsFxGraphData[] = [];

    inputData.data[0].currency_wise_daily_returns.currency_data.map(
      (date: any) => {
        const newDate: CurrencyVsFxGraphData = {};
        const getAllKeys = Object.keys(date);
        getAllKeys.forEach((key) => {
          if (key === 'date' || key.includes(currency)) {
            newDate[key] = date[key];
          }
        });
        returnData.push(newDate);
      }
    );

    return returnData.sort(
      (a: CurrencyVsFxGraphData, b: CurrencyVsFxGraphData) =>
        String(a.date).localeCompare(String(b.date), undefined, {
          numeric: true,
          sensitivity: 'base',
        })
    );
  }
};

const CurrencyVsFxGraph: React.FC<CurrencyVsFxGraphProps> = ({
  data,
  currency,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const builtGraphData = buildGraphData(data, currency);

  const id = 'Sub-Fund-Historical-Performance-Currency-vs-FX';
  const title = 'Sub-Fund Historical Performance - Currency vs FX';

  const { ref, handleDownload } = usePngFromRecharts();

  useEffect(() => {
    dispatch(
      addComponentToPdfExport({
        identifier: id,
        handler: handleDownload,
        type: PdfComponentType.LINE_CHART,
        title: title,
      })
    );
  }, [ref]);

  const saveImage = async () => {
    const imageData = await handleDownload();
    if (imageData) {
      FileSaver.saveAs(imageData.data, `${id}.png`);
    }
  };

  return (
    <GridItem xs={12} card cardStyle={{ height: '100%' }}>
      <div className={classes.toolbar}>
        <h2 className={classes.cardTitle}>{title}</h2>
        <div className={classes.exportButtons}>
          <ExportButton
            fileName={`${id}.csv`}
            exportData={builtGraphData}
            fields={Object.keys(builtGraphData[0])}
            // fieldsMap={[
            //   { key: 'date', label: 'Date' },
            //   { key: 'runningTotalPL', label: 'Total P/L' },
            //   { key: 'runningCurrencyPL', label: 'Currency P/L' },
            //   { key: 'runningAssetPL', label: 'Asset P/L' },
            // ]}
          />
          <ChartDownloadButton handler={saveImage} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={480}>
        <ComposedChart
          data={builtGraphData}
          margin={{ top: 20, right: 50, bottom: 20, left: 30 }}
          ref={ref}
        >
          <XAxis
            dataKey="date"
            angle={-45}
            textAnchor="end"
            height={60}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          />
          <YAxis
            width={70}
            tickFormatter={(tickItem) => {
              return percentageToNdecialPlaces(tickItem / 100, 2);
            }}
            tick={{
              stroke: mainColors.mainBlue,
              strokeWidth: 1,
              fontSize: '1.3rem',
            }}
            tickLine={true}
          >
            <Label
              style={{
                textAnchor: 'middle',
                fontSize: '200%',
                fontWeight: 400,
                color: mainColors.mainBlue,
              }}
              angle={270}
              value={'P/L'}
              position={'insideLeft'}
            />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid stroke={greys.grey300} strokeDasharray="5 5" />
          <ReferenceLine y={0} stroke={greys.grey600} />
          {Object.keys(builtGraphData[0]).map((key: any) => {
            if (key !== 'date') {
              if (key.includes('differences')) {
                return (
                  <Area
                    type="linear"
                    dataKey={key}
                    stroke={mainColors.pastelBlue}
                    fillOpacity={0.1}
                    fill={mainColors.pastelBlue}
                  />
                );
              } else {
                return (
                  <Line
                    key={key}
                    type="linear"
                    dataKey={key}
                    stroke={
                      key.includes('_fx')
                        ? mainColors.Pass
                        : mainColors.mainBlue
                    }
                    strokeWidth={2}
                  />
                );
              }
            }
          })}
          <Legend
            verticalAlign="top"
            height={30}
            wrapperStyle={{ fontSize: '1.6rem' }}
            formatter={(item) => {
              if (item.includes('_fx')) {
                return `${item.split('_fx')[0]} FX`;
              } else if (item.includes('differences')) {
                return `Difference`;
              } else {
                return item;
              }
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </GridItem>
  );
};

export default CurrencyVsFxGraph;
